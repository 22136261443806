import axios from "axios";
import Storage  from '././src/utils/Storage'
import router from "@/router";
import { Message } from "element-ui";
// axios.defaults.baseURL = "http://192.168.50.125:9123/api" //线下
// axios.defaults.baseURL = "http://47.96.184.13:9123/api" //线上
axios.defaults.baseURL = "https://xzjyzj.jietengkj.com/api/"  //线上

axios.defaults.timeout = 200000; //设置请求超时时间


//请求拦截器
// axios.interceptors.request.use(config => {
//     const token = window.localStorage.getItem('token')
//     if (token) { // 判断是否存在token，如果存在的话，则每个http header都加上token
//         config.headers.token = token  //请求头加上token
//     }
//     return config
// }, err => {
//     return Promise.reject(err)
// })
const request = axios.create({
	headers: {
		'Content-Type': "application/json; charset=utf-8"
	}
})

request.interceptors.request.use(config => {
	if (localStorage.getItem("token")) {
		config.headers['Authorization'] = localStorage.getItem("token")
	}
	return config

}, (error) => {
	return Promise.reject(error);
  })
request.interceptors.response.use(response=>{
	return response
},error=>{
	return Promise.reject(error)
})
// request.interceptors.response.use(function (response) { // ①10010 token过期（30天） ②10011 token无效
 
// 	if (response.data.code === 500 ) {
// 		Message.error(response.data.msg)
// 		Storage.localRemove('token') // 删除已经失效或过期的token（不删除也可以，因为登录后覆盖）
	 
// 		router.replace({
	 
// 			path: '/login' // 到登录页重新获取token
	 
// 		})
	 
// 	} else if (response.data.token) { // 判断token是否存在，如果存在说明需要更新token
	 
// 		Storage.localSet('token', response.data.token) // 覆盖原来的token(默认一天刷新一次)
	 
// 	}
	 
// 		return response
	 
// 	}, function (error) {
// 		return Promise.reject(error)
// 	})
export default request